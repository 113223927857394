import React from 'react'
import Robot from './robotnotfound-removebg-preview.png'
import './pagenotfound.css'

const Pagenotfound = () => {
  return (
    <div className='pagecontainer'>
    <img src={Robot} alt="Page Not Found"  className='pagenotimage'/>
      <h1 className='notfoundtitle1'>404 Page Not Found </h1>
      <p className='notfoundtitle3'>The link you followed probably broken or the page has been removed. </p>
    </div>
  )
}

export default Pagenotfound
