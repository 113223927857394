import React from 'react'
import Aboutpage from './containerpart/Aboutpage/Aboutpage'

const About = () => {
  return (
    <div>
      <Aboutpage/>
    </div>
  )
}

export default About
