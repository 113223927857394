import React from "react";
import "./Projectpage.css";
import Project from "./Project.json";
import project1 from './Project-Management-Post.jpg'

const Projectpage = () => {
  return (
    <div className="project">
      <h1 className="projecttitle"> Projects </h1>
      <div className="upperprojectsection">
      
      <div className="upperimage"><img src={project1} alt="" /></div>
      <div className="upperlink">
      <h1 className="uppertitle">All Project</h1>
      {Project.map((project, i) => ( 
        <div className="upperlinks">
        <a href={`#${project.id}`}>• {project.title}</a> </div>
      
      ))}
      </div>
      </div>
     

      <div className="projectbox">
        {Project.map((Project, i) => (
          <section id={Project.id}>
            <div key={i} className="projectcontainer">
              <div className="aboutimagecon">
                <img src={Project.img} alt="" className="aboutimage" />
              </div>
              <div className="projecttext">
                <h1 className="Pprojecttitlelist"> {Project.title}</h1>
                <ul className="projectable">
                  <li key={i} className="list7">
                    {Project.Discription.d1}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d2}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d3}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d4}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d5}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d6}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d7}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d8}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d9}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d10}
                  </li>

                  <li key={i} className="list7">
                    {Project.Discription.d11}
                  </li>
                  <li key={i} className="list7">
                    {Project.Discription.d12}
                  </li>
                </ul>
              </div>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default Projectpage;
