import React from 'react'
import './homesection2.css'
import video from './training.png'
import paper from './review.png'
import teacher from './expert.png'


const Homesections2 = () => {
  return (
    <div className='homesection2'>
    <div className='homesecpart2'>
      <div className='boxsec2'>
        <div className='imgbox'>
        <img src={video} alt="video" />
      </div>
      <h1>In-Depth Videos For 
      better Learning </h1>
      </div>
      <div className='boxsec2'>
      <div className='imgbox'>
      <img src={paper} alt="paper" />
      </div>
      <h1>Industry Standard Test 
      papers</h1>
      </div>
      <div className='boxsec2'>
      <div className='imgbox'>
      <img src={teacher} alt="teacher" />
      </div>
      <h1>Refrence Material to help 
      your progress</h1>
      </div></div>
    </div>
  )
}

export default Homesections2
