import React from 'react'
import Contactpart from './containerpart/Contactpart/Contactpart'

const Contact = () => {
  return (
    <div>
     <Contactpart/>
    </div>
  )
}

export default Contact
