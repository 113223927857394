import React from 'react'
import './Homemain.css'
import { useNavigate } from 'react-router-dom';

const Homemain = () => {
  const navigate = useNavigate();
  const placement = () => {
    // Navigate to a specific route
   
  navigate('/placement');
  }
  const courses = () => {
    // Navigate to a specific route
    
  navigate('/course');
  }
  return (
  <>
  <div className="container">
    <div className="outer">
      <div className="details">
      <div className='homeherosec1'>
      
        <h2 className='maintitle1'>UPGRADE YOUR SKILL SET</h2>
        <h1 className='maintitle2'>WITH EPITOMECIRCUITS</h1>
        <h3 className='maintitle3'>INSTITUTE OFFERS 30+ COURSES IN  DOMAIN</h3>
        <button className="buttonmain1" onClick={courses}>COURSES</button>
        <button className="buttonmain2" onClick={placement}>VIEW RECENT PLACEMENT </button>
      </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default Homemain
