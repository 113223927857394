import React from 'react';
import './registration.css';

const Register = () => {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "8d7f693e-0457-4d61-8afb-932f26e9d1ac");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
            alert("Form Submitted Successfully");
        } else {
            console.log("Error", data);
            setResult(data.message);
            alert('Error');
        }
    };

   
    return (
        <div className='register'>
            <h1 className='registertitle'>Registration Form</h1>
            <form id='form' onSubmit={onSubmit}>
                <h2>Personal Information</h2>
                <div className="form-group">
                    <label htmlFor="firstName">First Name:</label>
                    <input type="text" id="firstName" name="firstName" required />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name:</label>
                    <input type="text" id="lastName" name="lastName" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <input type="tel" id="phoneNumber" name="phoneNumber" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" rows="4" cols="50"></textarea>
                </div>

                <h2>Educational Background</h2>
                <div className="form-group">
                    <label htmlFor="tenthPercentage">10th %:</label>
                    <input type="number" id="tenthPercentage" name="tenthPercentage" required />
                </div>
                <div className="form-group">
                    <label htmlFor="tenthSchool">10th School:</label>
                    <input type="text" id="tenthSchool" name="tenthSchool" required />
                </div>
                <div className="form-group">
                    <label htmlFor="tenthYear">10th Year:</label>
                    <input type="number" id="tenthYear" name="tenthYear" required />
                </div>
                <div className="form-group">
                    <label htmlFor="twelvethDiplomaPercentage">12th/Diploma %:</label>
                    <input type="number" id="twelvethDiplomaPercentage" name="twelvethDiplomaPercentage" required />
                </div>
                <div className="form-group">
                    <label htmlFor="twelvethDiplomaCollege">12th/Diploma College:</label>
                    <input type="text" id="twelvethDiplomaCollege" name="twelvethDiplomaCollege" required />
                </div>
                <div className="form-group">
                    <label htmlFor="twelvethDiplomaYear">12th/Diploma Year:</label>
                    <input type="number" id="twelvethDiplomaYear" name="twelvethDiplomaYear" required />
                </div>
                <div className="form-group">
                    <label htmlFor="beCollege">B.E. College:</label>
                    <input type="text" id="beCollege" name="beCollege" />
                </div>
                <div className="form-group">
                    <label htmlFor="beYear">B.E. Year:</label>
                    <input type="number" id="beYear" name="beYear" />
                </div>
                <div className="form-group">
                    <label htmlFor="mTechPercentage">M.Tech %:</label>
                    <input type="number" id="mTechPercentage" name="mTechPercentage" />
                </div>
                <div className="form-group">
                    <label htmlFor="mTechCollege">M.Tech College:</label>
                    <input type="text" id="mTechCollege" name="mTechCollege" />
                </div>
                <div className="form-group">
                    <label htmlFor="mTechYear">M.Tech Year:</label>
                    <input type="number" id="mTechYear" name="mTechYear" />
                </div>
               
                <button type="submit" className='registerbutton'>Submit</button>
            </form>
            <div>{result}</div>
        </div>
    );
};

export default Register;
