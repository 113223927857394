import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Review.css";
import image from "./360_F_326985142_1aaKcEjMQW6ULp6oI9MYuv8lN9f8sFmj.jpg";

const Review = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="slider-container">
      <h2 className="reviewtitle">
        Student <b>Review</b>
      </h2>
      <Slider {...settings}>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
        
          </div>
          <div className="reviewtext">
          <h1>Vishwanath</h1>
          <p>EpitomeCircuits provides outstanding VLSI Design/Layout training at an industry level, utilizing industry-standard tools to equip students with the skills needed to excel in the VLSI industry. They offer excellent placement assistance and boast alumni in some of the most well-known VLSI companies.
          “ I am personally grateful to EpitomeCircuits for empowering me with the knowledge and experience essential for success in my role as a SOC Design Engineer at Intel."</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Jonathan Walters</h1>
          <p>The team was very supportive and kept me motivated
          “ EpitomeCircuits equipped me with the skills and knowledge necessary to excel in my new role in Layout Design. I am truly grateful for the opportunities they've opened up for me”</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Sneha M</h1>
          <p>EpitomeCircuits stands out as an excellent avenue for individuals with aspirations of venturing into the VLSI industry. This platform not only lets you stay updated on what's happening in the industry but also helps you learn both the theory and hands-on skills you need.
          During my final year, I joined VLSI Layout training at EpitomeCircuits, guided by experienced industry professionals. The training deepened my understanding of complex layouts like PLL, BGR, Op-amp, ADC, and SRAM. I'm grateful to my mentors for guiding me toward a career I've always aspired to pursue.</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Apeksha GD</h1>
          <p>I am happy to express my sincere gratitude to EpitomeCircuits for their invaluable guidance and support, which played a pivotal role in shaping my career journey.
          “ After completing my bachelor's, I joined EpitomeCircuits, where intensive training and mentorship equipped me with technical skills and industry insights. This rigorous program was crucial in helping me navigate the job market. I am delighted to share that, thanks to EpitomeCircuits' excellent training, I secured a layout engineer position at Texas Instruments, marking a significant career achievement.</p>
          
          </div>
        </div>
        <div className="reviewcon">
        <div className="reviewimage">
          <img src={image} alt="Slide 1" className="slide-imagea" />
          <div className="star">⭐⭐⭐⭐⭐</div>
          
        </div>
        <div  className="reviewtext">
        <h1>Jeanette Harmon </h1>
        <p>They provided a strong foundation and guided me through layout design sessions. Access to the tools 24/7 enhanced my layout skills.
        “ EpitomeCircuits played a vital role in securing my internship at Texas Instruments. With newfound confidence and efficient techniques, I look forward for a successful career in VLSI. I am really grateful to all the mentors for this wonderful learning experience. ”</p>
        
        </div>
      </div>
      
      </Slider>
    </div>
  );
};

export default Review;
