import React from 'react'
import Projectpage from './containerpart/Projectpage/Projectpage'

const Project = () => {
  return (
    <div>
      <Projectpage/>
    </div>
  )
}

export default Project
