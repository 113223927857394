import React from 'react'
import Coursespage from './containerpart/Coursespage/Coursespage'

const Course = () => {
  return (
    <div>
        <Coursespage/>
    </div>
  )
}

export default Course
