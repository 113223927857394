import React from 'react'
import file from './gallery.json'

const Gallery = () => {
  return (
    <div>
    <div>
    
    <div className="News">
    <h1 className="Newstitle">Gallery </h1>
    <div className="Newsbox">
    {file.map((file, i) => (
      <div key={i} className='newscontainer' >
     <div className='newsconimage'>
      <img src={file.img} alt="" className='newsimage' /></div>
      <div className='newtextcon'>
      <h1 className="newtextlist">{file.title}</h1>
        <ul className="newstablelist">
          <li>{file.disp1}</li>
        </ul></div>
      </div>
    ))}
  </div></div>
    </div>
    </div>
  )
}

export default Gallery
