import React from "react";
import news from "./news.json";
import "./News.css";

const News = () => {
  return (
    <div>
      <div className="News">
        <h1 className="Newstitle">News </h1>
        <div className="Newsbox">
          {news.map((news, i) => (
            <div key={i} className="newscontainer">
              <div className="newsconimage">
                <img src={news.img} alt="" className="newsimage" />
              </div>
              <div className="newtextcon">
                <h1 className="newtextlist">{news.title}</h1>
                <ul className="newstablelist">
                  <li>{news.disp1}</li>
                </ul>
                <a href={news.link}><button>Read More</button></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
