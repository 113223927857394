import React from 'react'
import Placementpages from './containerpart/placementpage/Placementpages'

const Placement = () => {
  return (
    <div>
    <Placementpages/>
    </div>
  )
}

export default Placement
