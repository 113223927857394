import React from 'react'
import placement from './placement.json'
import './placement.css'

const Placementpages = () => {
  return (
    <div>
    <div>
    <div>
    <div className="placement">
      <h1 className="placementtitle">Placement </h1>
      <div className="placementbox">
        {placement.map((placement, i) => (
          <div key={i} className="placementcontainer">
            <div className="placementconimage">
              <img src={placement.img} alt="" className="placementimage" />
            </div>
            <div className="placementtextcon">
              <h1 className="placementtextlist">{placement.Name}</h1>
              <ul className="placementtablelist">
                <li className='companyname'>{placement.company}</li>
                <li className='year'>{placement.year}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
    </div>
    </div>
  )
}

export default Placementpages
