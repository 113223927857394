import React from "react";
import './Footer.css'
import insta from "./instagram.png"
import faceb from "./facebook-removebg-preview.png"
import x from "./x-removebg-preview.png"
import github from "./github-icon-512x497-oppthre2.png"
import youtube from "./youtube-removebg-preview.png"

const Footer = () => {
  return (
    <>
    <div className="footer">
    <div className="footercontant">
      <div className="footer-section1 quicklink">
      <h2>Address</h2>
      <p>• EpitomeCircuits, near RNSIT, Dr. Vishnuvardhana Road Post, RR Nagar, Bengaluru - 560098</p>
      <p><a href="tel:+919513800500">• +91 9513800500</a></p>
      <p> <a href= "mailto: admin@epitomecircuits.com">• admin@epitomecircuits.com</a></p>
      </div>
      <div className="footer-section2 quicklink">
        <h2>Quick link</h2>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="about">About</a>
          </li>
          <li>
            <a href="project">Projects</a>
          </li>
          <li>
            <a href="course">Courses</a>
          </li>
        </ul>
      </div>
      <div className="footer-section3 quicklink">
        <h2>Quick link</h2>
        <ul>
          <li>
            <a href="placement">Placement</a>
          </li>
          <li>
            <a href="course">Online coruses</a>
          </li>
          <li>
            <a href="intenship">Intenship</a>
          </li>
          <li>
            <a href="contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
    <div className="line"></div>
    <div className="main11">
    <div className="socialmedia">
        <div><img src={insta} alt='instagram' className='socialicon'/></div>
        <div><img src={x} alt='x' className='socialicon'/></div>
        <div><img src={youtube} alt='youtube' className='socialicon youtube'/></div>
        <div> <img src={faceb} alt='facebook' className='socialicon'/></div>
        <div> <img src={github} alt='github' className='socialicon'/></div>
    </div></div>
    <div className="lastsection11">
      <p>Copyright © 2024, All Rights Reserved EpitomeCircuit. <a href="https://www.linkedin.com/in/harsh-patel-00371a283/" className="coded">Design & Developed by Harsh Patel</a></p>
    </div>
    </div>
   
    </>
  );
};

export default Footer;
